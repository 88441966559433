import { ReactElement } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCheckCircle,
  faExclamationCircle,
  faTimesCircle,
  faInfoCircle,
} from '@fortawesome/free-solid-svg-icons';

export enum AlertType {
  SUCCESS = 'SUCCESS',
  WARNING = 'WARNING',
  ERROR = 'ERROR',
  INFO = 'INFO',
}

const AlertColor = {
  [AlertType.SUCCESS]: 'bg-green-400',
  [AlertType.WARNING]: 'bg-yellow-400',
  [AlertType.ERROR]: 'bg-red-400',
  [AlertType.INFO]: 'bg-blue-400',
};

const AlertTitle = {
  [AlertType.SUCCESS]: 'Success',
  [AlertType.WARNING]: 'Warning',
  [AlertType.ERROR]: 'Error',
  [AlertType.INFO]: 'Info',
};

const AlertIcon = {
  [AlertType.SUCCESS]: faCheckCircle,
  [AlertType.WARNING]: faExclamationCircle,
  [AlertType.ERROR]: faTimesCircle,
  [AlertType.INFO]: faInfoCircle,
};

interface AlertProps {
  type?: AlertType;
  message?: string;
}

const Alert = ({
  message = '',
  type = AlertType.ERROR,
}: AlertProps): ReactElement => {
  if (!message) return <></>;
  return (
    <div>
      {/* Code block starts */}
      <div className="flex items-center justify-center my-2">
        <div className="transition duration-150 ease-in-out w-full mx-auto bg-white dark:bg-gray-800 shadow py-4 md:py-0 rounded flex flex-col items-center md:flex-row  justify-between">
          <div className="flex flex-col items-center md:flex-row">
            <div
              className={`mr-3 p-4 ${AlertColor[type]}  rounded md:rounded-tr-none md:rounded-br-none text-white`}
            >
              <FontAwesomeIcon icon={AlertIcon[type]} />
            </div>
            <p className="mr-2 text-base font-normal text-gray-800 dark:text-gray-100 mt-2 md:my-0">
              {AlertTitle[type]}
            </p>
            <div className="h-1 w-1 bg-gray-300 dark:bg-gray-700 rounded-full mr-2 hidden xl:block" />
            <p className="text-sm lg:text-base dark:text-gray-400 text-gray-600 lg:pt-1 xl:pt-0 sm:mb-0 mb-2 text-center sm:text-left">
              {message}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Alert;
