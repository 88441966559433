import api from '../utils/api';

// Import interfaces/types
import { User } from 'common/types/User.type';

type SignInSignUpResponse = {
  accessToken: string;
  user: {
    id: string;
    email: string;
    role: string;
  };
};

type ResetPasswordResponse = {
  responseMessage: string;
};

class AuthService {
  /**
   * Checks if user is signed in
   * @return {boolean} if user signed in
   */
  isSignedIn = () => {
    return localStorage.getItem('mtgp_token') !== null;
  };

  /**
   * return user auth access token
   * @return {string} access token
   */
  getToken = () => {
    return localStorage.getItem('mtgp_token');
  };

  /**
   * sign in user
   * @param {string} email email of user
   * @param {string} password password of user
   */
  signIn = async (email: string, password: string) => {
    const res: SignInSignUpResponse = await api.post(
      'api/auth/signin/dashboard',
      {
        email,
        password,
      }
    );

    if (res) {
      localStorage.setItem('mtgp_token', res.accessToken);
      localStorage.setItem('mtgp_user', JSON.stringify(res.user));
    }
  };

  /**
   * reset password
   * @param {string} email email of user
   */
  resetPassword = async (email: string) => {
    const res: ResetPasswordResponse = await api.post(
      'api/user/reset-password',
      {
        email,
      }
    );

    // eslint-disable-next-line no-empty
    if (res) {
    }
  };

  /**
   * return user auth user
   * @return {SignInSignUpResponse.user} user
   */
  getUser = (): User | null => {
    return JSON.parse(
      localStorage.getItem('mtgp_user') || 'null'
    ) as User | null;
  };

  /**
   * sign out current user
   */
  signOut = () => {
    localStorage.removeItem('mtgp_token');
    localStorage.removeItem('mtgp_user');
  };
}

export default new AuthService();
