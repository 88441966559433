import React, { ReactElement } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// Import icons
import { IconDefinition } from '@fortawesome/free-solid-svg-icons';

// Types
export interface Props {
  title: string;
  to: string;
  icon: IconDefinition;
}

function NavItem({ icon, to, title }: Props): ReactElement {
  return (
    <Link
      to={to}
      className="flex jusitfy-start items-center space-x-6 py-1 w-full  focus:outline-none  focus:text-indigo-400  text-white rounded "
    >
      <FontAwesomeIcon icon={icon} size="lg" />
      <p className="text-base leading-4 ">{title}</p>
    </Link>
  );
}

export default NavItem;
