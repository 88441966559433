import { ReactElement } from 'react';

// Import components
import { AbosluteSpinner } from 'components/Spinners';

export interface Column {
  slug: string;
  title: string;
  component?: ReactElement;
  className?: string;
  key?: string;
  // if clean render set to true render method will not add any parent element
  cleanRender?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  render?: (props: any, index: number) => any;
}

export interface Options {
  // which key on table data should be used as rendering key in react
  rowKey: number | string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onRowClick?: (props: any) => any;
}

export interface TableProps<T> {
  columns: Column[];
  data: T[];
  options: Options;
  loading?: boolean;
  className?: string;
}

function Table<T>({
  columns,
  data,
  options: { rowKey, onRowClick },
  loading = false,
  className = '',
}: TableProps<T>): ReactElement {
  // Renderers
  const renderColumns = () =>
    columns.map(({ title, slug, className }) => (
      <th
        key={slug}
        className={`font-normal text-lg text-left px-0 ${
          className ? className : ''
        }`}
      >
        {title}
      </th>
    ));

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const renderRowColumns = (item: T | any, index: number) => {
    return columns.map(
      ({ className, key, render, slug, cleanRender = false }) => (
        <td key={slug} className={`p-0 ${className ? className : ''}`}>
          {/* Render key string */}
          {key && !render && (
            <p className="text-md text-gray-600">{item[key || '']}</p>
          )}

          {/* Render combine key and render method */}
          {key && render && (
            <p className="text-md text-gray-600">{render(item[key], index)}</p>
          )}

          {/* Render with render method only */}
          {render &&
            !key &&
            (cleanRender ? (
              render(item, index)
            ) : (
              <p className="text-md text-gray-600 ">{render(item, index)}</p>
            ))}
        </td>
      )
    );
  };

  const renderRows = () => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return data.map((item: any, index): ReactElement => {
      const key = item[rowKey];
      return (
        <tr
          onClick={onRowClick ? () => onRowClick(item) : () => null}
          key={key}
          className={`h-14  p-5 text-sm leading-none hover:bg-gray-100 text-gray-800 bg-white border-b border-t border-gray-100
            ${onRowClick ? 'cursor-pointer' : ''}
            ${item.className ? item.className : ''}
          `}
        >
          {renderRowColumns(item, index)}
        </tr>
      );
    });
  };

  return (
    <div
      className={`bg-white rounded-b-lg relative shadow p-5 overflow-y-auto ${className}`}
    >
      <table className="w-full whitespace-nowrap">
        <thead>
          <tr className="h-12 w-full text-sm leading-none text-gray-800">
            {renderColumns()}
          </tr>
        </thead>
        <tbody className="w-full">
          {renderRows()}
          {!loading && !data.length && (
            <tr className="h-10 shadow-outer-progress ">
              <td
                colSpan={columns.length}
                className="w-full text-center text-gray-600 text-lg"
              >
                There are no data available!
              </td>
            </tr>
          )}
        </tbody>
      </table>
      <AbosluteSpinner show={loading} />
    </div>
  );
}

export default Table;
