// Imports
import { atom } from 'recoil';

// App imports
import authService from '../services/auth.service';

type UserAuth = {
  isAuthenticated: boolean;
};

// auth
export const userAuth = atom<UserAuth>({
  key: 'userAuth',
  default: {
    isAuthenticated: authService.isSignedIn(),
  },
});
