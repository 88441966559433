import { FC } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';

// Import services
import authService from 'services/auth.service';

// Import Components
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignOutAlt } from '@fortawesome/free-solid-svg-icons';
import NavItem from 'components/Navigation/NavItem';

// Import recoil atoms
import { userAtom } from 'store/userAtom';
import { userAuth } from 'store/auth';

// Import config
import { mainNavigationConfig } from 'config/navigationConfig';

const Navgiation: FC = () => {
  // State
  const setUserAuth = useSetRecoilState(userAuth);
  const setUser = useSetRecoilState(userAtom);

  // initiate state
  const { user } = useRecoilValue(userAtom);
  const role = user?.role;

  // handlers
  const handleSignOut = () => {
    setUserAuth({ isAuthenticated: false });
    setUser({ user: null });
    authService.signOut();
  };

  return (
    <div className="sticky z-50 bottom-0 top-0 left-0 min-h-screen translate-x-0 xl:rounded-r transform  xl:translate-x-0  ease-in-out transition duration-500 flex justify-start items-start h-full  w-full sm:w-64 bg-gray-900 flex-col">
      <div className="hidden xl:flex justify-start p-6 items-center space-x-3">
        <img className="w-full" src="/mtg-logo.png" alt="MTG Logo" />
      </div>

      <div className="mt-6 flex flex-col justify-start items-center  pl-4 w-full space-y-3 pb-5 ">
        {mainNavigationConfig.map(
          ({ title, to, icon, roles }) =>
            roles.includes(role!) && (
              <NavItem key={to} title={title} to={to} icon={icon} />
            )
        )}
      </div>

      {/* Footer */}
      <div className="absolute bottom-0 flex flex-col justify-between items-center h-auto py-4  px-6  w-full  space-y-32 border-t border-gray-600 ">
        <div className=" flex justify-between items-center w-full">
          <div className="flex justify-center items-center  space-x-2">
            {/* <div>
              <img
                className="rounded-full"
                src="https://i.ibb.co/L1LQtBm/Ellipse-1.png"
                alt="avatar"
              />
            </div> */}
            <div className="flex justify-start flex-col items-start">
              <p className="cursor-pointer text-sm leading-5 text-white">
                {user?.role}
              </p>
              <p className="cursor-pointer text-xs leading-3 text-gray-300">
                {user?.email}
              </p>
            </div>
          </div>

          <span onClick={() => handleSignOut()} className="cursor-pointer">
            <FontAwesomeIcon icon={faSignOutAlt} color="white" />
          </span>
        </div>
      </div>
    </div>
  );
};

export default Navgiation;
