// import { Button } from 'components/Button';
import { FC, ReactElement } from 'react';
import ReactDOM from 'react-dom';

// Import components
import { AbosluteSpinner } from 'components/Spinners';

type ModalProps = {
  closeButton?: boolean;
  onCloseClick?: () => void;
  onClickOutside?: () => void;
  noPadding?: boolean;
  title?: string;
  loading?: boolean;
  className?: string;
  icon?: ReactElement;
};
const Modal: FC<ModalProps> = ({
  closeButton,
  onCloseClick,
  onClickOutside,
  noPadding = false,
  loading = false,
  title,
  children,
  className = '',
  icon,
}) => {
  const showHeader = title || closeButton;
  const renderHeader = () =>
    showHeader && (
      <div className="bg-gray-100 rounded-tl-md rounded-tr-md px-6 py-5 flex items-center justify-between">
        {(title || icon) && (
          <div className="flex flex-row items-center">
            {icon ? <span className="mr-2"> {icon}</span> : null}
            {title ? (
              <p className="focus:outline-none text-xl font-normal">{title}</p>
            ) : null}
          </div>
        )}
        {closeButton && (
          <button
            onClick={!loading ? onCloseClick : () => null}
            className="focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 rounded-md focus:outline-none"
          >
            <svg
              width="28"
              height="28"
              viewBox="0 0 28 28"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M21 7L7 21"
                stroke="#A1A1AA"
                strokeWidth="1.75"
                strokeLinecap="round"
                strokeLinejoin="round"
              ></path>
              <path
                d="M7 7L21 21"
                stroke="#A1A1AA"
                strokeWidth="1.75"
                strokeLinecap="round"
                strokeLinejoin="round"
              ></path>
            </svg>
          </button>
        )}
      </div>
    );
  return ReactDOM.createPortal(
    <div className="flex flex-row items-center justify-center fixed inset-0 z-50 max-h-screen max-w-screen">
      <div
        className={`relative bg-white z-50 shadow max-h-full m-2 rounded-md ${className}`}
      >
        {renderHeader()}
        <div
          className={`h-auto overflow-hidden overflow-y-auto max-modal-height ${
            noPadding ? 'p-0' : 'p-6'
          }`}
        >
          {children}
        </div>
        <AbosluteSpinner show={loading} />
      </div>
      <div
        className="bg-overlay z-40 bg-gray-400 bg-opacity-60 absolute inset-0"
        onClick={!loading ? onClickOutside : () => null}
      />
    </div>,
    document.body
  );
};

export default Modal;
