import { FC, ReactElement, Suspense } from 'react';

// Import containers
import Navigation from 'containers/Navigation';

// Import components
import { AbosluteSpinner } from 'components/Spinners';
import { ErrorBoundry } from 'components/ErrorBoundry';

export interface DashboardLayoutProps {
  children: ReactElement;
}

const DashboardLayout: FC<DashboardLayoutProps> = ({ children }) => {
  return (
    <div className="min-h-screen min-w-full w-full flex">
      <Navigation />
      <div className="w-full relative flex flex-row">
        <div className="bg-gradient-to-b from-blue-darkgradient to-blue-lightgradient absolute -top-1 left-0 w-full h-30vh" />
        <div className="  w-full-minus-sidebar min-h-full relative p-4">
          <ErrorBoundry>
            <Suspense fallback={<AbosluteSpinner show />}>{children}</Suspense>
          </ErrorBoundry>
        </div>
      </div>
    </div>
  );
};

export default DashboardLayout;
