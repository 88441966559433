import { lazy } from 'react';
import { useRecoilValue } from 'recoil';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from 'react-router-dom';

// Import settings etc..
import routes from 'config/routesConfig';

// Import recoil state
import { userAuth } from 'store/auth';

// Import Layouts
import AuthLayout from './layouts/AuthLayout';
import DashboardLayout from 'layouts/DashboardLayout';

// Import Pages
import { Login } from 'pages/Auth/Login/index';
import ResetPassword from 'pages/Auth/Login/ResetPassword';
import { FreeContentListPage } from 'pages/FreeContent';
const Dashboard = lazy(() => import('pages/Dashboard/Dashboard'));
const UserListPage = lazy(() => import('pages/Users/UserListPage'));
const CourseListPage = lazy(() => import('pages/Courses/CourseListPage'));
const ProgramListPage = lazy(() => import('pages/Program/ProgramListPage'));
const ModuleListPage = lazy(() => import('pages/Modules/ModuleListPage'));
const LessonListPage = lazy(() => import('pages/Lessons/LessonListPage'));
const LessonPage = lazy(() => import('pages/Lessons/LessonPage'));
const SubscriptionListPage = lazy(
  () => import('pages/Subscription/SubscriptionListPage')
);

/**
 * App component returning root react component
 * @return {JSX.Element} root react component
 */
function App(): JSX.Element {
  const auth = useRecoilValue(userAuth);
  return (
    <Router>
      {/* Auth Routes */}
      {!auth.isAuthenticated && (
        <AuthLayout>
          <Routes>
            <Route path={routes.auth.signIn.path} element={<Login />} />
            <Route
              path="*"
              element={<Navigate to={routes.auth.signIn.path} />}
            />

            <Route
              path={routes.auth.resetPassword.path}
              element={<ResetPassword />}
            />
          </Routes>
        </AuthLayout>
      )}

      {auth.isAuthenticated && (
        <DashboardLayout>
          <Routes>
            {/* Dashboard Routes */}
            <Route path={routes.dashboard.index.path} element={<Dashboard />} />

            {/* Users Management Routes */}
            <Route path={routes.users.index.path} element={<UserListPage />} />

            {/* Free Content Routes */}
            <Route
              path={routes.freeContents.index.path}
              element={<FreeContentListPage />}
            />

            {/* Programs Routes */}
            <Route
              path={routes.programs.index.path}
              element={<ProgramListPage />}
            />

            {/* Courses Routes */}
            <Route
              path={routes.programs.program.path}
              element={<CourseListPage />}
            />

            {/*  Module Routes */}
            <Route
              path={routes.courses.modules.path}
              element={<ModuleListPage />}
            />

            {/* Lesson Routes */}
            <Route
              path={routes.courses.lessons.path}
              element={<LessonListPage />}
            />
            <Route path={routes.courses.lesson.path} element={<LessonPage />} />

            {/* Subscriptions Management Routes */}
            <Route
              path={routes.subscriptions.index.path}
              element={<SubscriptionListPage />}
            />

            {/* Wildcards */}
            <Route
              path="*"
              element={<Navigate to={routes.dashboard.index.path} />}
            />
          </Routes>
        </DashboardLayout>
      )}
    </Router>
  );
}

export default App;
