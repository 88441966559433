export enum UserRole {
  ADMIN = 'ADMIN',
  TEACHER = 'TEACHER',
  STUDENT = 'STUDENT',
}

export interface CreateUser {
  email: string;
  role: UserRole;
  password: string;
}

export interface UpdateUser {
  id: string;
  role: UserRole;
  password: string;
}

export interface User {
  id: string;
  email: string;
  role: UserRole;
  password: string;
  updatedAt: Date;
  createdAt: Date;
}
