const config = {
  baseUrl: process.env.REACT_APP_API_URL,
  clientUrl: process.env.REACT_APP_CLIENT_URL,
};

type configType = typeof config;

export const getConfig = (): configType => {
  if (!config.baseUrl)
    throw new Error('There is no API url (baseUrl) specified!');
  if (!config.clientUrl) throw new Error('There is no client url specified!');

  return config;
};
