import {
  ForwardRefRenderFunction,
  forwardRef,
  useImperativeHandle,
  useState,
} from 'react';

import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

// Types
import { FreeContentType } from 'common/types/FreeContent.type';

// Services
import freeContentsService from 'services/freeContents.service';

// Components
import { Alert } from 'components/Alert';
import { Button } from 'components/Button';
import { Input } from 'components/Form/Input';
import { Modal } from 'components/Modal';
import { TextArea } from 'components/Form/TextArea';
import { Checkbox } from 'components/Form/Checkbox';

const freeContentSchema = yup.object().shape({
  id: yup.string(),
  title: yup
    .string()
    .min(5, 'Title can not be lest then 5 charascters')
    .required('Title is required'),
  summary: yup
    .string()
    .min(5, 'Summary can not be lest then 5 charascters')
    .required('Summary is required'),
  description: yup
    .string()
    .min(5, 'Description can not be lest then 5 charascters')
    .required('Description is required'),
  content: yup
    .object()
    .nullable()
    .shape({
      version: yup.string(),
      type: yup.string().required('Please select content type!'),
      data: yup.object().shape({
        id: yup.string(),
        posterImage: yup
          .string()
          .required('Poster image item content is required!'),
        posterImageTitle: yup
          .string()
          .required('Poster image title is required!'),
        videoUrl: yup.string().required('List item content is required!'),
      }),
    }),
  featured: yup.bool().default(false),
});

interface Props {
  onRefresh: () => void;
  freeContentId?: string;
}

type EditorHandle = {
  show: (freeContent?: FreeContentType) => void;
};

const FreeContentEditor: ForwardRefRenderFunction<EditorHandle, Props> = (
  { onRefresh, freeContentId }: Props,
  ref
) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [show, setShow] = useState(false);
  const [freeContent, setFreeContent] = useState<FreeContentType>();

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm<FreeContentType>({
    resolver: yupResolver(freeContentSchema),
  });

  // handlers
  const handleClose = () => {
    reset();
    setShow(false);
    setFreeContent(undefined);
  };

  const onCreate = async (data: FreeContentType) => {
    setLoading(true);
    freeContentsService
      .createFreeContent(data)
      .then((freeContent) => {
        setLoading(false);
        setFreeContent(freeContent);
        handleClose();
        onRefresh();
      })
      .catch((error) => {
        setError(error.message);
      });
  };

  const onUpdate = (data: FreeContentType) => {
    setLoading(true);
    if (freeContent?.id) {
      freeContentsService
        .updateFreeContent(freeContent.id, data)
        .then((updatedFreeContent) => {
          setLoading(false);
          setFreeContent(updatedFreeContent);
          handleClose();
          onRefresh();
        })
        .catch((error) => {
          setLoading(false);
          setError(error.message);
        });
    }
  };
  const handleOnSubmit = (data: FreeContentType) => {
    if (!freeContent) {
      onCreate(data);
      return undefined;
    }
    onUpdate({ ...data, id: freeContent.id });
  };
  const handleShow = (_freeContent?: FreeContentType) => {
    reset();
    if (_freeContent) {
      setFreeContent(_freeContent);
      setValue('title', _freeContent.title);
      setValue('summary', _freeContent.summary);
      setValue('description', _freeContent.description);
      setValue('content.type', _freeContent.content.type);
      setValue('content.version', _freeContent.content.version);
      setValue('content.data.videoUrl', _freeContent.content.data.videoUrl);
      setValue(
        'content.data.posterImage',
        _freeContent.content.data.posterImage
      );
      setValue(
        'content.data.posterImageTitle',
        _freeContent.content.data.posterImageTitle
      );
      setValue('featured', _freeContent.featured);
    }
    if (!_freeContent) {
      reset();
      setFreeContent(undefined);
    }
    setError('');
    setShow(true);
    setLoading(false);
  };

  // Ref interface
  useImperativeHandle(ref, () => ({
    show: handleShow,
  }));

  // Renderers
  if (!show) return <></>;

  return (
    <Modal
      title={freeContent ? 'Update Free Content' : 'Create Free Content'}
      closeButton
      onCloseClick={handleClose}
      onClickOutside={handleClose}
      loading={loading}
    >
      <Alert message={error} />
      <form
        onSubmit={handleSubmit(handleOnSubmit)}
        className="flex flex-col w-96 space-y-4"
      >
        <Input
          {...register('title')}
          label="Title"
          placeholder="Title"
          type="text"
          errors={errors}
        />
        <Input
          {...register('summary')}
          label="Summary"
          placeholder="Summary"
          errors={errors}
        />
        <TextArea
          {...register('description')}
          label="Description"
          placeholder="Description"
          errors={errors}
        />
        <Input
          {...register('content.version')}
          placeholder="Version"
          value="1.0"
          hidden
        />
        {/* <Input
          {...register('content.type')}
          placeholder="type"
          value="video"
          hidden
          disabled
        /> */}
        <input value="video" {...register('content.type')} hidden />
        {/* <Select
          {...register('content.type')}
          options={{
            items: [
              {
                label: 'Video',
                value: 'video',
              },
              //   {
              //     label: 'Image',
              //     value: 'image',
              //   },
              //   {
              //     label: 'Article',
              //     value: 'article',
              //   },
            ],
          }}
          errors={errors}
          placeholder="select free content type"
          className="hidden"
        /> */}
        <Input
          className="mb-2 flex-grow"
          label={'Video url'}
          placeholder={'video url'}
          {...register('content.data.videoUrl')}
          errors={errors}
        />
        <Input
          className="mb-2 flex-grow"
          label={'Poster image'}
          placeholder={'Poster image'}
          {...register('content.data.posterImage')}
          errors={errors}
        />
        <Input
          className="mb-2 flex-grow"
          label={'PosterImage title'}
          placeholder={'Poster image title'}
          {...register('content.data.posterImageTitle')}
          errors={errors}
        />

        <Checkbox
          placeholder="Featured"
          label="Featured"
          {...register('featured')}
          errors={errors}
        />

        <Button type="submit">Save</Button>
      </form>
    </Modal>
  );
};

export default forwardRef(FreeContentEditor);
