import { forwardRef } from 'react';
import { FieldErrors, FieldValues } from 'react-hook-form';

interface TextAreaProps {
  placeholder: string;
  shadow?: boolean;
  className?: string;
  rows?: number;
  name?: string;
  errors?: {
    [key: string]: FieldErrors<FieldValues>;
  };
  label?: string;
  disabled?: boolean;
}

const TextArea = forwardRef<HTMLTextAreaElement, TextAreaProps>(
  (
    {
      placeholder = '',
      shadow = false,
      className = '',
      rows = 4,
      children,
      label,
      name,
      errors,
      ...props
    },
    ref
  ) => {
    return (
      <div className="text-gray-700 flex flex-col">
        {label && <label className="text-gray-700">{label}:</label>}
        <textarea
          ref={ref}
          placeholder={placeholder}
          rows={rows}
          className={[
            'bg-white border border-gray disabled:bg-gray-200 rounded disabled:opacity-70 focus:outline-none text-sm font-normal leading-none text-gray-800 py-3 w-full pl-3 mt-2',
            shadow ? 'filter drop-shadow-md' : '',
            className,
          ].join(' ')}
          name={name}
          {...props}
        >
          {children}
        </textarea>
        {name && errors?.[name] && (
          <label className="text-red-700 ml-4">{errors?.[name].message}</label>
        )}
      </div>
    );
  }
);

export default TextArea;
