import { forwardRef } from 'react';
import { FieldErrors, FieldValues } from 'react-hook-form';

interface CheckboxProps {
  placeholder: string;
  shadow?: boolean;
  size?: 'large' | 'medium' | 'small';
  className?: string;
  name?: string;
  errors?: {
    [key: string]: FieldErrors<FieldValues>;
  };
  label?: string;
}

const Input = forwardRef<HTMLInputElement, CheckboxProps>(
  (
    {
      placeholder,
      shadow = false,
      size = 'small',
      className = '',
      label,
      name,
      errors,
      ...props
    },
    ref
  ) => {
    // const TextFieldSize = {
    //   large: 'text-2xl',
    //   medium: 'text-lg',
    //   small: 'text-base',
    // };

    return (
      <div className="text-gray-700 flex flex-col">
        <div className="py-4 flex items-center">
          <div className="bg-white dark:bg-gray-800 border rounded-sm border-gray-400 dark:border-gray-700 w-4 h-4 flex flex-shrink-0 justify-center items-center relative">
            <input
              id={name}
              name={name}
              type="checkbox"
              ref={ref}
              placeholder={placeholder}
              aria-label={label}
              className="absolute cursor-pointer w-full h-full"
              {...props}
            />
          </div>
          <label htmlFor={name} className="text-sm leading-none ml-2">
            {label}
          </label>
        </div>
        {name && errors?.[name] && (
          <label className="text-red-700 ml-4">{errors?.[name].message}</label>
        )}
      </div>
    );
  }
);

export default Input;
