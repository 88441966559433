import { ElementRef, ReactElement, useEffect, useRef, useState } from 'react';

import {
  faCheck,
  faEdit,
  faPlus,
  faTimes,
  faTrashAlt,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// Types
import { FreeContentType } from 'common/types/FreeContent.type';
import { ListResult } from 'common/types/ListResult.type';

// Utils
import { trimString } from 'utils/stringUtils';

// Services
import freeContentsService from 'services/freeContents.service';

// Components
import { Alert } from 'components/Alert';
import { Button } from 'components/Button';
import { Column, Table, TableHeader } from 'components/Table';
import FreeContentEditor from './FreeContentEditor';

const FreeContentListPage = (): ReactElement => {
  type FreeContentHandler = ElementRef<typeof FreeContentEditor>;
  const createFreeContentRef = useRef<FreeContentHandler>(null);

  const [freeContents, setFreeContents] =
    useState<ListResult<FreeContentType>>();
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);

  const showFreeContentEditor = () => {
    createFreeContentRef.current?.show();
  };
  const handleGetFreeContents = async () => {
    setLoading(true);
    freeContentsService
      .getFreeContents()
      .then((freeContent) => {
        setLoading(false);
        setFreeContents(freeContent);
      })
      .catch((error) => {
        setLoading(false);
        setError(error.message);
      });
  };
  const onDeleteFreeContent = (freeContent: FreeContentType) => {
    if (
      // eslint-disable-next-line no-restricted-globals
      confirm(
        'Are you sure you want to delete this free content? All its content will be deleted.'
      )
    ) {
      setLoading(true);
      freeContentsService
        .deleteFreeContent(freeContent.id)
        .then(() => {
          onRefreshFreeContents();
        })
        .catch((err) => {
          setLoading(false);
          setError(err.message);
        });
    }
  };

  const onEditFreeContent = (freeContent: FreeContentType) => {
    createFreeContentRef.current?.show(freeContent);
  };

  const onRefreshFreeContents = () => {
    handleGetFreeContents();
  };

  useEffect(() => {
    handleGetFreeContents();
  }, []);

  // Declarations
  const tableColumns: Column[] = [
    {
      slug: 'title',
      title: 'Title',
      key: 'title',
      render: (title: string) => (
        <span className="relative truncate">{trimString(title, 50)}</span>
      ),
    },
    {
      slug: 'description',
      title: 'Description',
      key: 'description',
      cleanRender: true,
      render: (description: string) => (
        <span className="relative truncate">{trimString(description, 80)}</span>
      ),
    },
    {
      slug: 'featured',
      title: 'Featured',
      key: 'featured',
      className: 'text-right pr-4 w-40',
      cleanRender: true,
      render: (featured: boolean) => {
        return (
          <span className="text-center w-full">
            {featured ? (
              <FontAwesomeIcon icon={faCheck} />
            ) : (
              <FontAwesomeIcon icon={faTimes} />
            )}
          </span>
        );
      },
    },
    {
      slug: 'actions',
      title: 'Actions',
      className: 'pl-20 text-right w-1',
      cleanRender: true,
      render: (freeContent: FreeContentType) => {
        const canDelete = freeContent;
        return (
          <span className="text-right pt-1">
            <span
              className="mr-2 cursor-pointer"
              onClick={() => onEditFreeContent(freeContent)}
            >
              <FontAwesomeIcon icon={faEdit} />
            </span>
            <span
              className={`mr-2 ${canDelete ? 'cursor-pointer' : 'opacity-20'}`}
              onClick={
                canDelete ? () => onDeleteFreeContent(freeContent) : () => null
              }
            >
              <FontAwesomeIcon icon={faTrashAlt} />
            </span>
          </span>
        );
      },
    },
  ];
  return (
    <div className="relative">
      <Alert message={error} />
      <TableHeader title="Free contents">
        <Button onClick={() => showFreeContentEditor()}>
          <FontAwesomeIcon
            icon={faPlus}
            color="white"
            className="mr-2 -mt-0.5"
          />
          <span className="text-white font-bold">New Free Content</span>
        </Button>
      </TableHeader>
      <Table<FreeContentType>
        data={freeContents?.items || []}
        columns={tableColumns}
        options={{ rowKey: 'id' }}
        loading={loading}
        className="w-full overflow-x-auto"
      />

      <FreeContentEditor
        ref={createFreeContentRef}
        onRefresh={onRefreshFreeContents}
      />
    </div>
  );
};

export default FreeContentListPage;
