import { FC, ReactElement } from 'react';

interface AuthLayoutProps {
  children: ReactElement;
}

const AuthLayout: FC<AuthLayoutProps> = ({ children }: AuthLayoutProps) => {
  //   const [sidebar, setsidebar] = useState();
  return (
    <div className="h-full min-h-screen bg-gradient-to-tl from-green-400 to-indigo-900 w-full py-16 px-4">
      <div className="flex flex-col items-center justify-center">
        {children}
      </div>
    </div>
  );
};

export default AuthLayout;
