import { forwardRef } from 'react';
import { FieldErrors, FieldValues } from 'react-hook-form';
import { get as _get } from 'lodash';

interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  placeholder: string;
  round?: boolean;
  color?: 'white' | 'blue';
  shadow?: boolean;
  type?: string;
  className?: string;
  name?: string;
  errors?: {
    [key: string]: FieldErrors<FieldValues>;
  };
  label?: string;
  required?: boolean;
}

const Input = forwardRef<HTMLInputElement, InputProps>(
  (
    {
      placeholder,
      type = 'text',
      color = 'white',
      shadow = false,
      round = false,
      className = '',
      label,
      name,
      errors,
      required = false,
      ...props
    },
    ref
  ) => {
    const Color = {
      white: 'bg-white border-black border-1',
      blue: 'bg-blue',
    };
    const error = _get(errors, name || '', null);
    return (
      <div
        className={`text-gray-700 my-2 flex flex-col ${
          className ? className : ''
        }`}
      >
        {label && (
          <label className="text-gray-700">
            {label}:{required ? <span className="text-red-700">*</span> : ''}
          </label>
        )}
        <input
          ref={ref}
          type={type}
          placeholder={placeholder}
          name={name}
          className={[
            'bg-white border border-gray rounded outline-none disabled:bg-gray-200 disabled:opacity-70 focus:outline-none text-sm font-thin leading-none text-gray-800 py-3 w-full pl-3 mt-2',
            Color[color],
            shadow ? 'filter drop-shadow-md' : '',
          ].join(' ')}
          {...props}
        />
        {error && <label className="text-red-700 ml-4">{error.message}</label>}
      </div>
    );
  }
);

export default Input;
