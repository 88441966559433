// Import types
import { UserRole } from 'common/types/User.type';

// Import icons
import {
  IconDefinition,
  faTh,
  faListAlt,
  faUserAlt,
} from '@fortawesome/free-solid-svg-icons';

export interface MainNavigationItem {
  title: string;
  to: string;
  icon: IconDefinition;
  roles: UserRole[];
}

export const mainNavigationConfig: MainNavigationItem[] = [
  {
    title: 'Dashboard',
    to: '/dashboard',
    icon: faTh,
    roles: [UserRole.ADMIN, UserRole.TEACHER],
  },
  {
    title: 'Users',
    to: '/users',
    icon: faUserAlt,
    roles: [UserRole.ADMIN],
  },
  {
    title: 'Programs',
    to: '/programs',
    icon: faListAlt,
    roles: [UserRole.ADMIN, UserRole.TEACHER],
  },
  {
    title: 'Subscriptions',
    to: '/subscriptions',
    icon: faListAlt,
    roles: [UserRole.ADMIN],
  },
  {
    title: 'Free Content',
    to: '/free-content',
    icon: faListAlt,
    roles: [UserRole.ADMIN],
  },
];
