// Imports
import { atom } from 'recoil';

// App imports
import authService from '../services/auth.service';

import { User } from 'common/types/User.type';

// auth
export const userAtom = atom<{ user: User | null }>({
  key: 'user',
  default: {
    user: authService.getUser() as User | null,
  },
});
