// Import types
import { UserRole } from 'common/types/User.type';

// Declare interfaces
export interface ConfigRoute {
  path: string;
  roles: UserRole[];
}

export interface ConfigRoutes {
  [key: string]: {
    [key: string]: ConfigRoute;
  };
}

// Declare config
const routes: ConfigRoutes = {
  auth: {
    signIn: {
      path: '/auth/sign-in',
      roles: [],
    },
    resetPassword: {
      path: '/reset-password',
      roles: [],
    },
  },
  dashboard: {
    index: {
      path: '/dashboard',
      roles: [UserRole.ADMIN, UserRole.TEACHER],
    },
  },
  users: {
    index: {
      path: '/users',
      roles: [UserRole.ADMIN],
    },
  },
  programs: {
    index: {
      path: '/programs',
      roles: [UserRole.ADMIN, UserRole.TEACHER],
    },
    program: {
      path: '/program/:programId/courses',
      roles: [UserRole.ADMIN, UserRole.TEACHER],
    },
  },
  courses: {
    index: {
      path: '/courses',
      roles: [UserRole.ADMIN, UserRole.TEACHER],
    },
    modules: {
      path: '/course/:courseId/modules',
      roles: [UserRole.ADMIN, UserRole.TEACHER],
    },
    lessons: {
      path: '/course/:courseId/module/:moduleId/lessons',
      roles: [UserRole.ADMIN, UserRole.TEACHER],
    },
    lesson: {
      path: '/course/:courseId/module/:moduleId/lesson/:lessonId',
      roles: [UserRole.ADMIN, UserRole.TEACHER],
    },
  },
  freeContents: {
    index: {
      path: '/free-content',
      roles: [UserRole.ADMIN, UserRole.TEACHER],
    },
  },

  subscriptions: {
    index: {
      path: '/subscriptions',
      roles: [UserRole.ADMIN],
    },
  },
};

export default routes;
