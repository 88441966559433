import { FC, useState } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useNavigate } from 'react-router-dom';

import { Input } from 'components/Form/Input';
import { Alert } from 'components/Alert';
import authService from 'services/auth.service';

// Declarations
const resetPasswordSchema = yup.object().shape({
  email: yup.string().email().required('Email is required'),
});

const ResetPassword: FC = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ resolver: yupResolver(resetPasswordSchema) });
  const [error, setError] = useState('');
  const navigate = useNavigate();

  // handlers
  const handleClose = () => {
    navigate('/auth/sign-in?passwordChanged=true');
  };

  const onSubmit = async (data: Record<string, string>) => {
    authService
      .resetPassword(data.email)
      .then((response) => {
        handleClose();
      })
      .catch((error) => {
        setError(error.message);
      });
  };

  return (
    <>
      <img src="/mtg-logo.png" alt="MTG Logo" />
      <div className="bg-white shadow rounded lg:w-1/3  md:w-1/2 w-full p-10 mt-16">
        <Alert message={error} />
        <p
          aria-label="Login to your account"
          className="text-2xl font-extrabold leading-6 text-gray-800"
        >
          Reset your password
        </p>
        <div className="w-full flex items-center justify-between py-5">
          <p className="text-base font-medium leading-4 px-2.5 text-gray-400"></p>
          <hr className="w-full bg-gray-400  " />
        </div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="mt-6  w-full">
            <span className="text-sm font-medium leading-none text-gray-800">
              Email
            </span>
            <div className="relative flex items-center justify-center">
              <Input
                type="email"
                placeholder="example@madetogrow.no"
                className="bg-gray-200 border rounded focus:outline-none text-xs font-medium leading-none text-gray-800 py-3 w-full pl-3 mt-2"
                {...register('email')}
                errors={errors}
              />
            </div>
          </div>
          <div className="mt-8">
            <button
              aria-label="Signin"
              className="focus:ring-2 focus:ring-offset-2 focus:ring-indigo-700 text-sm font-semibold leading-none text-white focus:outline-none bg-indigo-700 border rounded hover:bg-indigo-600 py-4 w-full"
            >
              Reset password
            </button>
          </div>
        </form>
      </div>
    </>
  );
};

export default ResetPassword;
