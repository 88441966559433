import { ReactElement, ReactNode } from 'react';

interface Props {
  title: string;
  children: ReactNode;
  childrenClassName?: string;
  className?: string;
}

const TableHeader = ({
  title,
  children,
  childrenClassName = '',
  className = '',
}: Props): ReactElement => {
  return (
    <div className={`p-5 bg-gray-100 rounded-tl-lg rounded-tr-lg ${className}`}>
      <div className="sm:flex items-center justify-between">
        <p className="text-base sm:text-lg md:text-xl lg:text-2xl font-bold leading-normal text-gray-800">
          {title}
        </p>
        <div className={`flex flex-row items-center ${childrenClassName}`}>
          {children}
        </div>
      </div>
    </div>
  );
};

export default TableHeader;
