import api from 'utils/api';

// Import types
import { ListResult } from 'common/types/ListResult.type';
import { FreeContentType } from 'common/types/FreeContent.type';

class FreeContentsService {
  getFreeContents = async (): Promise<ListResult<FreeContentType>> => {
    const res: ListResult<FreeContentType> = await api.get('api/free-content');
    return res;
  };

  getFreeContent = async (id?: string): Promise<FreeContentType> => {
    const res: FreeContentType = await api.get(`api/free-content/${id}`);
    return res;
  };

  createFreeContent = async (
    freeContent: FreeContentType
  ): Promise<FreeContentType> => {
    const res: FreeContentType = await api.post(
      'api/free-content',
      freeContent
    );

    return res;
  };

  updateFreeContent = async (
    freeContentId: string,
    freeContent: FreeContentType
  ): Promise<FreeContentType> => {
    if (!freeContent.id) {
      throw new Error('FreeContent ID is required');
    }
    const res: FreeContentType = await api.patch(
      `api/free-content/${freeContentId}`,
      freeContent
    );
    return res;
  };

  deleteFreeContent = async (id: string): Promise<void> => {
    await api.delete(`api/free-content/${id}`);
  };
}

export default new FreeContentsService();
