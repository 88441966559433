import axios, { AxiosError, AxiosResponse } from 'axios';
import authService from '../services/auth.service';

// Import config
import { getConfig } from './config';

const API = axios.create({
  baseURL: getConfig().baseUrl,
  responseType: 'json',
});

API.interceptors.request.use((request) => {
  if (!request.headers) {
    request.headers = {};
  }
  request.headers.Accept = 'application/json';

  const jwtToken = authService.getToken();
  if (jwtToken) {
    // Automatically attach JWT for authenticated API requets
    request.headers.Authorization = `Bearer ${jwtToken}`;
  }

  if (request.method === 'POST' || request.method === 'PUT') {
    // Let the backend know we're sending JSON data
    request.headers['Content-Type'] = 'application/json';
  }

  return request;
});

API.interceptors.response.use(
  (response: AxiosResponse) => response.data,
  (error: AxiosError) => {
    if (error.response?.status === 401) {
      authService.signOut();
      return Promise.reject(new Error('Unauthorized'));
    }
    return Promise.reject(error.response?.data || error);
  }
);

export default API;
